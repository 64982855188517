@import "../../constants";

.story-container {
    text-align: left;
    h5 {
        padding: 40px 0;
    }
}

.sub-title-desc {
    margin-top: 16px;
}

.affiliation-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 300px;
    @include phone {
        height: 400px;
    }
    h5 {
        padding: 0;
    }
    p {
        padding: 40px;
        @include phone {
            padding: 16px 25px;
        }
    }
}

.flip-card-wics{
    //margin-top: 16px;
    //margin-right: 16px;
    width: 50%;
    @include tablet {
        width: 90%;
    }
    //height: 270px;
    /*
    @media (max-width: 1300px) {
        width: 350px;
     }
     @media (max-width: 1150px) {
        width: 270px;
        height: 330px;
     }
     @media (max-width: 800px) {
        width: 350px;
     }
     @media (max-width: 580px) {
        font-size: 16px;
        width: 270px;
         height: 240px;
     }
    perspective: 1000px;
    */
    @media (max-width: 350px) {
         height: 360px;
     }
}

.flip-card-inner-wics{
    transition: transform 0.6s;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.flip-card-wics:hover .flip-card-inner-wics{
    transform: rotateY(180deg);
}

.wics-front, .wics-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $light-pink;
    color: $text-dark;
    @include phone {
        padding: 0px;
        text-align: left;
        img {

        }
    }
}

.wics-back {
  transform: rotateY(180deg);
}

.flip-card-wie{
    margin-top: 16px;
    width: 400px;
    height: 270px;
    @media (max-width: 1300px) {
        width: 350px;
     }
     @media (max-width: 1150px) {
        width: 270px;
        height: 330px;
     }
     @media (max-width: 800px) {
        width: 350px;
     }
     @media (max-width: 580px) {
        font-size: 16px;
        width: 270px;
         height: 240px;
     }
    perspective: 1000px;
}

.flip-card-inner-wie{
    transition: transform 0.6s;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.flip-card-wie:hover .flip-card-inner-wie{
    transform: rotateY(180deg);
}

.wie-front, .wie-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $light-pink;
    color: $text-dark;
}

.wie-back {
  transform: rotateY(180deg);
 
}
.affiliation-image-mobile {
    width: 90%;
    max-height: 150px;
}

.affiliation-Title {
    margin-top: 8px;
    font-size: 20px;
}

.affiliated-title{
    @media (max-width: 580px) {
        font-size: 24px;
     }
}

.affiliation-image-desktop {
    width: 40%;
    max-height: 150px;
    @media (max-width: 800px) {
        width: 70%;
        
     }
}

.affiliation-wrapper-desktop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 800px) {
        flex-direction: column;
        justify-content: space-between;
     }
}

.wics-desc{
    width: 60%;
    @media (max-width: 800px) {
        width: 100%;
        margin-top: 24px;
     }
}