@import "../../constants";

.our-sponsors {
  &-container {
    text-align: left;
    @include phone {
      height: auto;
    }
    a {
      &:hover {
        opacity: 0.5;
        transform: translateY(-5px);
        transition: 0.1s linear;
      }
    }
  }

  &-title {
    margin-top: 50px;
    margin-bottom: 24px;
    @include phone {
      text-align: left;
    }
  }

  &-logos {
    padding: 36px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      width: 90%;
      align-self: center;
      justify-self: center;
      text-align: center;
      img {
        width: 100%;
        text-align: center;
      }
    }
    @include phone {
      padding: 16px;
    }
  }

  &-gold {
    width: 60%;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    a {
      padding-top: 20px;
      max-width: 650px;
      text-align: center;
    }
    @include tablet {
      a {
        max-width: 450px;
      }
    }
    @include phone {
      width: 100%;
      a {
        padding-top: 10px;
        max-width: none;
      }
    }
  }

  &-silver {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    display: grid;
    gap: 20px;
    @include tablet {
      gap: 0px;
    }
    @include phone {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px;
      justify-content: space-around;
      gap: 8px;
      a {
        img {
          max-width: 100%;
        }
      }
      .pagerduty {
        grid-column-start: 1;
        grid-column-end: 3;
        width: 50%;
        align-items: center;
        justify-self: center;
      }
    }
  }

  &-bronze {
    margin-top: 60px;
    padding: 0 80px;
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    a {
      max-width: 150px;
    }
    @include tablet {
      a {
        min-width: 80px;
      }
    }
    @include phone {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
      gap: 8px;
      a {
        width: 100%;
        img {
          height: 100%;
        }
      }
      .d2l {
        grid-column-start: 2;
      }
    }
  }

  &-startup {
    margin-top: 60px;
    width: 18%;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    a {
      min-width: 60px;
    }
    @include tablet {
      width: 25%;
      a {
        min-width: 50px;
      }
    }
    @include phone {
      width: 38%;
      margin-top: 20px;
      gap: 16px;
      a {
        min-width: 0;
        width: 100%;
        img {
          height: 100%;
        }
      }
    }
  }

  &-partners {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    border-radius: 25px;
    a {
      width: 40%;
      max-width: 300px;
      padding: 0 20px;
      img {
        width: 100%;
      }
    }
    @include tablet {
      a {
        margin-top: 20px;
        width: 50%;
      }
    }
    @include phone {
      padding: 0 0 20px 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a {
        width: 80%;
        max-width: 250px;
      }
    }
  }

  &-special {
    display: flex;
    align-items: center;
    padding-top: 50px;
     @include tablet {
       flex-direction: column;
       img {
         padding: 24px;
       }
     }
  }
}
