@import "../../constants";

.carousel {
  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 36px;
    margin-bottom: 24px;
    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &-image {
    width: 30%;
    @media (max-width: 900px) {
      width: 70%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &-sponsor-image {
    width: 100%;
    @media (max-width: 1200px) {
      max-width: 50%;
      max-height: 70px;
      width: auto;
    }
    @media (max-width: 900px) {
      max-height: 50px;
      width: auto;
    }
    @media (max-width: 500px) {
      max-width: 70%;
      max-height: 50px;
      width: auto;
    }
  }

  &-sponsor-image-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-speech-wrapper {
    width: 90%;
    @include tablet {
      padding: 0 40px;
    }
    @include phone {
      padding: 0;
      width: 100%;
    }
  }

  &-speech-bubble,
  &-sponsor-bubble {
    box-sizing: border-box;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: 1200px) {
      height: 250px;
    }
    @media (max-width: 1100px) {
      height: 280px;
    }
    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 300px;
    }
    @include phone {
      height: 370px;
    }
    @include small-phone {
      height: 430px;
    }
  }

  &-sponsor-bubble {
    height: 450px;
    background-color: #dfe5fa;
    border-radius: 25px;
    padding: 0 44px;
    width: 100%;
    @media (max-width: 1200px) {
      height: 700px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 32px;
    }
    @include tablet {
      height: 100%;
      padding: 32px;
      width: 100%;
    }
    @include phone {
      height: 400px;
      padding: 16px;
      overflow-y: scroll;
    }
    @include small-phone {
      height: 400px;
    }
  }

  &-background-bubble {
    display: inline;
    margin-right: -5px;
    margin-top: 50px;
  }

  &-background-bubble2 {
    display: block;
    margin-bottom: -25px;
    margin-right: 200px;
  }

  &-text {
    padding: 24px 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 30px;
    background-color: #dfe5fa;
    color: $text-dark;
    p {
      margin-bottom: 10px;
    }
    @include phone {
      padding: 16px;
    }
  }

  &-sponsor-text {
    width: 200%;
    color: $text-dark;
    a {
        color: $text-dark;
    }
    margin-left: 50px;
    @media (max-width: 1200px) {
      margin-top: 16px;
      margin-left: 0;
      width: 100%;
    }
    padding: 0;
  }

  &-quote-name-role {
    font-weight: bold;
  }

  &-progress-bar {
    background-color: #c0cbf2;
    height: 10px;
    border-radius: 5px;
    width: 65%;
    @media (max-width: 900px) {
      width: 50%;
    }
  }

  &-controls-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 55px;
    align-items: center;
    margin-top: 15px;
    @media (max-width: 900px) {
      margin-left: 10px;
    }
  }

  &-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 6%;
    min-width: 40px;
    margin-right: 15px;
    img {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &-link-wrapper {
    @include phone {
      text-align: center;
    }
  }
}

#carousel-progress-made {
  background-color: #7676e8;
  height: 10px;
  width: 33%;
  border-radius: 5px;
}

a {
  cursor: pointer;
}
