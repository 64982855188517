@import "../../constants";

.footer {
    &-title {
        font-family: $font-logo;
        font-size: 18px;
        font-weight: 600;
        @include phone {
            font-size: 16px;
        }
    }
    &-container {
        height: 118px;
        background-color: $light-pink;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            padding-top: 5px;
            font-size: 14px;
            @include phone {
                font-size: 16px;
            }
        }
        a {
            margin-top: 5px;
            font-size: 14px;
        }
        @include phone {
            height: 114px;
        }
    }
    &-logo-container {
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100%;
            margin-right: 10px;
        }
    }
}
