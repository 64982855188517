@import "../../constants";

@keyframes bounce-up-landing {
    0% {
        transform: translateY(50px);
        opacity: 1;
    }
    50% {
        transform: translateY(30px);
        opacity: 0.7;
    }
    100% {
        transform: translateY(50px);
        opacity: 1;
    }
}

@keyframes bounce-up-mobile {
    0% {
        transform: translateY(4px);
        opacity: 1;
    }
    50% {
        transform: translateY(-4px);
        opacity: 0.7;
    }
    100% {
        transform: translateY(4px);
        opacity: 1;
    }
}

@keyframes bounce-up-fast {
    30% {
        transform: translateY(-20px);
    }
    70% {
        transform: translateY(-10px);
    }
    0%,
    10%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
}

.landing {
    &-container {
        height: 90vh;
        width: 100%;
        display: inline-grid;
        grid-template-columns: minmax(480px, 57%) 46%;
        grid-template-rows: 60% 35% 5%;
        grid-template-areas:
            "landing-text landing-graphic"
            "landing-button landing-graphic"
            "landing-learn-more landing-learn-more";
        @include tablet {
            height: 95vh;
            padding-top: 50px;
            grid-template-columns: 300px auto;
            grid-template-rows: minmax(250px, 35%) 50% 50px;
            grid-template-areas:
                "landing-text landing-text"
                "landing-button landing-graphic"
                "landing-learn-more landing-learn-more"; 
        }
        @include phone {
            height: 85vh;
            grid-template-columns: auto;
            grid-template-rows: min-content min-content min-content;
            grid-template-areas:
                "landing-graphic"
                "landing-text"
                "landing-button";
        }
        @include small-phone {
            height: 95vh;
        }
    }

    &-text {
        align-self: flex-end;
        justify-self: flex-start;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
            "text-title"
            "text-slogan"
            "text-description"
            "text-dates"; 
        font-family: $font-regular;
        width: 100%;
        min-width: 500px;
        grid-area: landing-text;
        text-align: left;
        @include tablet {
            min-width: 0;
        }
        @include phone {
            align-items: center;
            text-align: center;
            grid-template-rows: auto auto auto;
            grid-template-areas:
            "text-title"
            "text-slogan"
            "text-dates"
            "text-description"; 
        }
    }

    &-description-mobile {
        display: none;
        @include phone {
            display: block;
            grid-area: text-description;
            font-weight: 600;
            text-align: center;
            justify-self: center;
            width: 270px;
            font-size: 14px;
            font-weight: 400;
        }
        @include small-phone {
            font-size: 14px;
        }
    }
    &-description {
        grid-area: text-description;
        @include phone {
            display: none;
        }
    }
    &-dates {
        grid-area: text-dates;
        @include phone {
            font-size: 16px;
            font-weight: 600;
        }
        @include small-phone {
            font-size: 14px;
        }
    }

    &-title {
        font-size: $text-logo;
        font-family: $font-logo;
        @include tablet {
            font-size: $text-large;
        }
        @include phone {
            font-size: 24px;
        }
    }
    &-title-mobile {
        font-family: $font-logo;
        font-size: 20px;
    }

    &-logo-text {
        grid-area: text-title;
        @include phone {
            display: none;
        }
    }
    
    &-logo-text-mobile {
        display: none;
        @include phone {
            .landing-title {
                font-size: 18px;
                font-weight: 600;
                font-family: $font-logo;
            }
            grid-area: text-title;
            display: flex;
            align-self: flex-end;
            margin: 20px 0 0 0;
        }
    }

    &-apps-closed {
        max-width: 540px;
        margin-top: 16px;
        font-weight: bold;
        @include phone {
            visibility: hidden;
            display: none;
        }
    }

    &-apps-closed-mobile {
        
        visibility: hidden;
        @include phone {
            max-width: 540px;
            visibility: visible;
            align-self: flex-end;
            margin: 20px 0 0 0;
        }
    }
    

    &-slogan {
        grid-area: text-slogan;
        font-size: $text-xxlarge;
        font-family: "Quicksand";
        &-bold {
            font-family: "Quicksand Bold";
        }
        @include desktop {
            font-size: 58px;
        }
        @include tablet {
            font-size: $text-xlarge;
        }
        @include phone {
            font-size: 36px;
        }
        @include small-phone {
            font-size: 30px;
        }
    }

    &-graphic-container {
        align-self: center;
        padding-top: 50px;
        @include phone {
            padding-top: 60px;
        }
        img {
            margin-left: 30px;
            animation: bounce-up-landing 3s linear infinite;
            @include tablet {
                margin-left: 0;
                width: 100%;
                animation: bounce-up-mobile 3s linear infinite;
            }
            @include phone {
                width: 90%;
                max-height: 300px;
            }
        }
        grid-area: landing-graphic;
    }

    &-button-container {
        grid-area: landing-button;
        height: auto;
        align-self: flex-start;
        justify-self: flex-start;
        padding-top: 40px;
        display: flex;
        gap: 16px;
        @include tablet {
            padding-top: 30px;
            flex-direction: column;
        }
        @include phone {
            display: none;
        }
    }

    &-button-container-mobile {
        display: none;
        @include phone {
            display: flex;
            gap: 8px;
            padding-top: 16px;
            flex-direction: row;
            justify-self: center;
            a {
                font-size: 16px;
                padding: 10px 15px;
            }
        }
        @include small-phone {
            padding-top: 10px;
            a {
                font-size: 14px;
                padding: 10px 12px;
            }
        }
    }

    &-learn-more-container {
        grid-area: landing-learn-more;
        display: flex;
        justify-content: center;
        justify-self: center;
        @include tablet {
            align-items: flex-start;
        }
        @include phone {
            display: none;
        }
    }
}

.learn-more {
    &-link {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        &:link {
            text-decoration: none;
            font-weight: 400;
        }
        &:hover {
            animation: bounce-up-fast 1.5s linear infinite;
        }
    }
    &-arrow {
        margin-top: 5px;
        width: 50%;
    }
}

h4 {
    margin-top: 5px;
    @include phone {
        line-height: 24px;
        margin-top: 10px;
    }
    @include small-phone {
        line-height: 21px;
        margin-top: 5px;
    }
}


