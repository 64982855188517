@import "../../constants";

.keynote-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 80px;
    padding: 32px;
    align-items: center;
    background-color: #DFE5FA;
    @media (max-width: 1200px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 32px;
      }
    @include tablet {
       display: block;
       justify-content: center;
     }
     border-radius: 25px;
}

.keynote-text {
    display: block;
    text-align: left;
    width: 60%;
    @media (max-width: 1200px) {
        width: 100%;
      }
    color: #19123c;
}

.keynote-title{
    text-align: left;
}

.keynote-image{
    width: 315px;
    height: 350px;
    @include tablet {
        width: 215px;
    height: 250px;
      }
}
