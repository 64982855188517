@import "../../constants";

@keyframes bounce-about {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(20px);
        opacity: 0.7;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes bounce-down {
    0% {
        transform: translateY(20px);
        opacity: 0.7;
    }
    50% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(20px);
        opacity: 0.7;
    }
}

.about {
    &-container {
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    &-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 40%;
        @include phone {
            flex-direction: column;
        }
    }

    &-text {
        padding: 20px, 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-family: $font-regular;
        font-size: $text-medium;
        p {
            margin-top: 15px;
        }
        @include phone {
            width: 100%;
            p {
                margin-top: 10px;
            }
        }
    }

    &-events {
        margin-top: 30px;
        display: grid;
        text-align: center;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 70px auto auto;
        grid-template-areas:
            "prep-title hack-title connect-title"
            "prep-image hack-image connect-image"
            "prep-caption hack-caption connect-caption";

        img {
            align-self: flex-end;
            justify-self: center;
            width: 80%;
            margin: 20px 0;
        }

        @include tablet {
            grid-template-columns: 100%;
            grid-template-rows: 70px auto auto auto auto auto auto auto auto;
            grid-template-areas:
                "prep-title"
                "prep-image"
                "prep-caption"
                "hack-title"
                "hack-image"
                "hack-caption"
                "connect-title"
                "connect-image"
                "connect-caption";
        }
    }

    &-caption {
        margin: 20px;
        width: 90%;
        justify-self: center;
    }
}

.prep-image {
    animation: bounce-about 3s linear infinite;
}
.hack-image {
    animation: bounce-down 3s linear infinite;
}
.connect-image {
    animation: bounce-about 3s linear infinite;
}

.button-smaller-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
/*
.button-smaller {
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $accent-pink;
    color: $text-light;
    font-size: $text-small;
    font-family: "Poppins";
    font-weight: 700;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
    @include phone {
        font-size: 16px;
      }
    

    &:hover,
    &:focus {
        opacity: 0.7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: translateY(-5px);
        transition: 0.1s linear;
    }
}
*/
